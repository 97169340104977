<template>
  <section class="_slider-option" ref="sliderOption">
    <div class="_media">
      <picture class="_picture" ref="picture1">
        <source
          media="(min-width: 1200px)"
          srcset="@/assets/images/homepage_slider-option_1-1200.jpg"
        />
        <source
          media="(min-width: 600px)"
          srcset="@/assets/images/homepage_slider-option_1-Small.jpg"
        />
        <img
          class="_image"
          src="@/assets/images/homepage_slider-option_1-Small.jpg"
          alt="5bhk"
        />
      </picture>
      <picture class="_picture" ref="picture2">
        <source
          media="(min-width: 1200px)"
          srcset="@/assets/images/homepage_slider-option_2-1200.jpg"
        />
        <source
          media="(min-width: 600px)"
          srcset="@/assets/images/homepage_slider-option_2-Small.jpg"
        />
        <img
          class="_image"
          src="@/assets/images/homepage_slider-option_2-Small.jpg"
          alt="3bhk"
        />
      </picture>
    </div>
    <div class="_slider__content" ref="sliderContent">
      <div class="_slider__wrapper">
        <div class="_card--raised" ref="card1">
          <div class="_card__wrapper" ref="card1Wrapper">
            <h1 class="_title">5 Bedroom Riverview Villa</h1>
            <p class="_text">
              Spread across 6000 sq.ft, the riverview villa offers 5 bedrooms
              which can lend itself to upto 32 guests. Every bedroom offers
              abundance of space and privacy. Spending your holiday at the
              riverview villa is all you need to unwind and relax with your
              family and friends.
            </p>
            <div class="_links">
              <router-link :to="{ name: 'LuxuryVilla' }" class="_link"
                >Know More</router-link
              >
            </div>
          </div>
        </div>
        <div class="_card--raised" ref="card2">
          <div class="_card__wrapper" ref="card2Wrapper">
            <h1 class="_title">3 Bedroom Riverside Cottage</h1>
            <p class="_text">
              Imagine waking up to the sound of a river in the comfort of a cozy
              cottage. This homely abode expands across 2000 sq.ft. and
              accommodates up to 8 guests. The Riverside cottage is perfect for
              intimate gatherings.
            </p>
            <div class="_links">
              <router-link :to="{ name: 'RiversideCottage' }" class="_link"
                >Know More</router-link
              >
            </div>
          </div>
        </div>
        <!-- <div class="_card--blank"></div> -->
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "AutoSlider",
  data() {
    return {
      sliderContentTL: null,
    };
  },

  methods: {
    initSliderContent() {
      console.log("initSliderContent");
      const sliderOption = this.$refs.sliderOption;
      const sliderContent = this.$refs.sliderContent;
      const sliderContentScrollTrigger = {
        trigger: sliderContent,
        start: () => {
          if (sliderOption.offsetWidth < 400) {
            return "top 30%";
          } else if (sliderOption.offsetWidth < 600) {
            return "top 40%";
          } else if (sliderOption.offsetWidth < 1200) {
            return "top 50%";
          } else {
            return "top 55%";
          }
        },
        // end: "top top",
        toggleActions: "play auto auto reverse",
        // scrub: 1,
        invalidateOnRefresh: true,
        // markers: true,
      };
      const timelineDuration = 0.5;
      this.sliderContentTL = gsap.timeline({
        defaults: { duration: timelineDuration, ease: "linear" },
        scrollTrigger: sliderContentScrollTrigger,
      });
      this.sliderContentTL.to(
        sliderContent,
        {
          x: () => {
            const diff = sliderOption.offsetWidth - sliderContent.scrollWidth;
            // console.log(diff);
            return diff;
          },
        },
        "main"
      );
      this.sliderContentTL.fromTo(
        this.$refs.card1,
        {
          scale: () => {
            return sliderOption.offsetWidth > 599 ? 1.1 : 1;
          },
          transformOrigin: "top center",
        },
        {
          scale: "1",
        },
        "desktop-only-=timelineDuration"
      );
      this.sliderContentTL.fromTo(
        this.$refs.card2,
        {
          scale: "1",
          transformOrigin: "top center",
        },
        {
          scale: () => {
            return sliderOption.offsetWidth > 599 ? 1.1 : 1;
          },
          transformOrigin: "top center",
        },
        "desktop-only-=timelineDuration"
      );
      this.sliderContentTL.fromTo(
        this.$refs.card1Wrapper,
        {
          opacity: 1,
        },
        {
          opacity: 0.4,
        },
        "card1-wrapper-=timelineDuration"
      );
      this.sliderContentTL.fromTo(
        this.$refs.card2Wrapper,
        {
          opacity: 0.4,
        },
        {
          opacity: 1,
        },
        "card2-wrapper-=timelineDuration"
      );
      this.sliderContentTL.fromTo(
        this.$refs.picture1,
        { opacity: 1, width: "100%" },
        { opacity: 0, width: "0%" },
        "picture1-=timelineDuration"
      );
      this.sliderContentTL.fromTo(
        this.$refs.picture2,
        { opacity: 0, width: "0%" },
        { opacity: 1, width: "100%" },
        "picture2-=timelineDuration"
      );
    },

    onResize() {
      // ToDo : Timeline doesn't invalidate onResize
      // scaling doesn't happen unless scrolled past trigger point
      //
      // this.sliderContentTL.invalidate();
      // this.sliderContentTL.refresh();
      ScrollTrigger.refresh();
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);

    // Ideally we should not need to trigger ScrollTrigger.refresh()
    // Although, we need to set a timeOut as below if route changes
    this.initSliderContent();
    setTimeout(() => {
      console.log("Timeout Refresh (Looks hacky!?)");
      ScrollTrigger.refresh();
    }, 3000);
  },
  beforeDestroy() {
    console.log("SliderContent Destroyed");
    this.sliderContentTL.invalidate();
    this.sliderContentTL.kill();
    this.sliderContentTL.scrollTrigger.kill();
  },
};
</script>

<style scoped lang="scss">
._slider-option {
  overflow-x: hidden;
}
._slider-option {
  display: grid;
  grid-template-rows: auto 100px auto;
  ._media {
    height: 300px;
    grid-area: 1/1/3/-1;
    display: grid;
    // grid-template-columns: 1fr 1fr;
    ._picture {
      grid-area: 1/1/-1/-1;
      &:nth-child(1) {
        justify-self: start;
      }
      &:nth-child(2) {
        justify-self: end;
      }
    }
  }
  ._slider__content {
    grid-area: 2/1/4/-1;
    z-index: 1;
    // @extend ._slider;
    padding: 0 0 Calc($paddingMinimum * 2);
    ._slider__wrapper {
      display: grid;
      grid-template-columns: 1px 60% 60% 1px;
      gap: 30px;
      // Since we have a gap:30px, we just need to
      // render additional 1px columns in the _slider__wrapper grid
      // and add empty ::before & ::after to fake left-right margins
      &::before,
      &::after {
        content: "";
      }
    }
    ._card--raised {
      display: flex;
      margin: 0;
      &:nth-child(1) {
        // margin-left: 30px;
        grid-column: 2;
      }
      &:nth-child(2) {
        // margin-right: 30px;
        grid-column: 3;
      }
    }
    ._card__wrapper {
      display: grid;
      grid-template-rows: min-content auto;
      ._links {
        align-self: end;
      }
    }
  }
}
@media screen and (min-width: 600px) {
  ._slider-option {
    ._media {
      height: 400px;
    }
    ._slider__content {
      padding: 0 0 Calc($paddingMinimum * 3);
      ._slider__wrapper {
        gap: 60px;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  ._slider-option {
    ._media {
      height: 500px;
    }
    ._slider__content {
      ._slider__wrapper {
        grid-template-columns: 1px 1fr 1fr 1px;
        // grid-template-rows: 350px;
        gap: 90px;
      }
    }
  }
}
</style>