<template>
  <footer>
    <div class="footer__wrapper">
      <div class="footer__container">
        <ul class="footer__col--1">
          <li class="footer__title">Quick Links</li>
          <!-- <li class="footer__link">
            <a class="_link" href="">Privacy Policy</a>
          </li>
          <li class="footer__link">
            <a class="_link" href="">Terms & Conditions</a>
          </li>
          <li class="footer__link">
            <a class="_link" href="">Cancellation Policy</a>
          </li> -->
          <li class="footer__link">
            <router-link class="_link" :to="{ name: 'LuxuryVilla' }"
              >5 Bedroom Villa</router-link
            >
          </li>
          <li class="footer__link">
            <router-link class="_link" :to="{ name: 'RiversideCottage' }"
              >3 Bedroom Cottage</router-link
            >
          </li>
        </ul>
        <ul class="footer__col--2">
          <li class="footer__title">Our Address</li>
          <li class="footer__paragraph">
            Bhadra Farms, Vanjarwadi Village, Karjat Murbad Highway, Karjat,
          </li>
        </ul>
        <ul class="footer__col--3">
          <li class="footer__title">For Enquiries</li>
          <li class="footer__paragraph">
            +91 9987519816 / +91 9833167278 contact@kamalfarms.com
          </li>
        </ul>
        <ul class="footer__col--4">
          <li class="footer__title">Follow Us</li>
          <li class="footer__icons">
            <!-- <i class="fab fa-facebook-f"></i> -->
            <a
              href="https://www.facebook.com/kamalfarmskarjat/"
              target="_blank"
            >
              <img
                class="_social-icon"
                src="@/assets/icon_facebook.svg"
                alt="facebook social icon"
            /></a>
            <a
              href="https://instagram.com/kamalfarms?igshid=19xtjb9xbeb1p"
              target="_blank"
            >
              <img
                class="_social-icon"
                src="@/assets/icon_instagram.svg"
                alt="instagram social icon"
            /></a>
            <!-- <i class="fab fa-instagram"></i> -->
            <!-- <i class="fab fa-youtube"></i> -->
          </li>
          <li class="footer__paragraph">© Kamal Farms, Karjat | 2020</li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.footer {
  &__wrapper {
    margin-top: 100px;
    //height: 220px;
    background-color: $dark-green;
    display: flex;
    align-items: center;
  }
  &__container {
    @extend .container;
    width: 100%;
    padding: $padding-buffer;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  &__title {
    @extend ._subtitle;
    color: $white;
    border-bottom: 1px solid $white;
    display: inline-block;
    margin-bottom: 2rem;
  }
  &__paragraph {
    @extend ._text;
    color: $white;
  }
  &__link {
    ._link {
      color: $white;
      border-bottom: none;
    }
  }
  &__icons {
    list-style-type: none;
    ._social-icon {
      width: 25px;
      height: 25px;
      margin-left: 20px;
    }
  }
  &__col {
    &--1 {
      display: none;
    }
    &--4 {
      grid-column: 1 / 4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
      .footer__title {
        display: none;
      }
    }
  }
}

// ** Important - Move all media queries in a single declaration
@media screen and (max-width: 400px) {
  .footer {
    &__container {
      grid-template-columns: 1fr;
    }
    &__icons {
      ._social-icon {
        margin: 10px 10px 20px 0;
      }
    }
    &__col {
      &--4 {
        grid-column: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
}
@media (min-width: 768px) {
  .footer {
    &__wrapper {
      //height: 220px;
    }
    &__container {
      grid-template-columns: repeat(3, 1fr);
    }
    &__col {
      &--1 {
        display: block;
      }
    }
  }
}
@media (min-width: 1024px) {
  .footer {
    &__wrapper {
      //height: 220px;
    }
    &__container {
      grid-template-columns: repeat(4, 1fr);
    }
    &__icons {
      margin-bottom: 2rem;
      ._social-icon {
        margin: 0 20px 0 0;
      }
    }
    &__col {
      &--4 {
        grid-column: 4;
        display: unset;
        .footer__title {
          display: inline-block;
        }
      }
    }
  }
}
</style>
