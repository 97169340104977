<template>
  <div class="_homepage">
    <section class="_section__hero-wrapper">
      <section class="_section__custom--slide-show">
        <SlideShow></SlideShow>
      </section>
    </section>

    <section class="_section__slider-option-wrapper">
      <section class="_section__custom--slider-option">
        <AutoSlider></AutoSlider>
      </section>
    </section>

    <section class="_section__custom--simple">
      <div class="_media">
        <picture class="_picture">
          <source
            media="(min-width: 1200px)"
            srcset="@/assets/images/homepage_about-1200.jpg"
          />
          <source
            media="(min-width: 600px)"
            srcset="@/assets/images/homepage_about-Small.jpg"
          />
          <img
            loading="lazy"
            class="_image"
            src="@/assets/images/homepage_about-Small.jpg"
            alt="about"
          />
        </picture>
      </div>
      <div class="_card__custom">
        <h1 class="_title">About - A Riverside Abode amidst Nature</h1>
        <div class="_paragraphs">
          <p class="_text">
            Nature helps us unwind and reconnect with ourselves. The fort-like
            structure of the homestead sits on the banks of the Pej river. The
            calming melody of the river and the greenery of the farm is
            rejuvenating.
          </p>
          <p class="_text">
            Kamal farms expands across 5 acres of fertile farmland. The
            Riverside abode lends itself to guests for small and large
            gatherings. We ensure attentive service, spacious and comfortable
            accommodation, and cuisine based on organic farm produce. Be it a
            family get together, a corporate retreat, or a chic destination
            wedding, the space lends itself for a soothing experience to adults
            and children alike.
          </p>
        </div>
        <div class="_links">
          <router-link :to="{ name: 'About' }" class="_link"
            >About Us</router-link
          >
        </div>
      </div>
    </section>

    <section class="_section__testimonials">
      <h2 class="_title">Testimonials</h2>
      <div class="_slider">
        <div
          class="_card--light"
          v-for="item in this.testimonies"
          :key="item.id"
        >
          <div class="_content">
            <p class="_text">
              {{ item.comment }}
            </p>
            <div class="_rating">
              <h3 class="_rating__title">{{ item.name }}</h3>
              <div class="_rating__icons">
                <div
                  class="_rating__icon"
                  :class="{ '_rating__icon--active': i <= item.rating }"
                  v-for="i in 5"
                  :key="i"
                ></div>
              </div>
              <div class="_rating__image"></div>
            </div>
          </div>
        </div>
        <!-- <div></div> -->
      </div>
    </section>

    <section class="_section__map" id="location-map">
      <div ref="locationMap" class="_media" id="map"></div>
      <div class="_card--raised">
        <h2 class="_title">Location</h2>
        <p class="_text">
          Kamal Farms is located approximately 70 kilometers away from Mumbai
          and 100 kilometers from Pune in the small town of Karjat. Situated on
          the banks of Pej river, blessed trees and an expansive farm spread
          across 10,000 sq.ft of land. It is a perfect location to host visitors
          with absolute care.
        </p>
        <div class="_links">
          <router-link
            :to="{ name: 'About', hash: '#about-location' }"
            class="_link"
            >Know More</router-link
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SlideShow from "@/components/SlideShow.vue";
import AutoSlider from "@/components/AutoSlider.vue";
// import { Loader } from "@googlemaps/js-api-loader";
export default {
  name: "Homepage",
  metaInfo: {
    title: "Kamal Farms Karjat",
    titleTemplate: null,
    meta: [
      {
        property: "og:title",
        content: "Kamal Farms Karjat",
      },
      {
        property: "twitter:title",
        content: "Kamal Farms Karjat",
      },
    ],
  },
  data() {
    return {
      mapObject: null,
      testimonies: [
        {
          id: 1,
          name: "Dr. P. B. Kankaria",
          comment:
            "Had a good stay, good food, good hospitality. Would like to come again in future.",
          rating: 4,
        },
        {
          id: 2,
          name: "Deepak Shah",
          comment: "Loved the hospitality and the villa. Thank you.",
          rating: 4,
        },
        {
          id: 3,
          name: "Sumant Singh",
          comment:
            "Thanks for the great hospitality and services. We enjoyed our stay. The food was very homely and tasty. The staff is very understanding and helpful.",
          rating: 4,
        },
        {
          id: 4,
          name: "Dibyapran",
          comment:
            "Beautiful property with an amazing river front view. Friendly staff and delicious food.",
          rating: 4,
        },
        {
          id: 5,
          name: "Ameet",
          comment:
            "Property is very well maintained. Food is excellent. Overall a very pleasant and peaceful stay. Much appreciation for the service provided by the caretaker.",
          rating: 4,
        },
      ],
    };
  },
  components: {
    SlideShow,
    AutoSlider,
  },
  methods: {
    initGoogleMaps() {
      if (window.google) {
        this.mapObject = new window.google.maps.Map(this.$refs.locationMap, {
          center: { lat: 18.97678774558809, lng: 73.40093536757571 },
          zoom: 17,
        });
      } else {
        console.log("Maps not available!!!");
      }
    },
  },
  mounted() {
    this.initGoogleMaps();
  },
};
</script>

<style scoped lang="scss">
._homepage {
  ._section__custom--slide-show {
    @extend ._section__custom;
    margin-bottom: 0;
  }
  ._section__slider-option-wrapper {
    @extend ._section--bg;
    background-position: 100% 100%;
    background-size: 80% 80%;
  }
  ._section__custom--slider-option {
    @extend ._section__custom;
  }
  ._section__testimonials {
    @extend ._section__custom;
    padding-bottom: $paddingMinimum;
  }
  ._section__map {
    @extend ._section__custom--overlap;
    ._media {
      background-color: grey;
      width: 100%;
      height: 600px;
      background-size: cover;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    ._card--raised {
      @extend ._card--light;
    }
  }
}
@media screen and (min-width: 600px) {
  ._homepage {
    ._section__slider-option-wrapper {
      background-size: 60% 80%;
    }
  }
}
@media screen and (min-width: 1200px) {
  ._homepage {
    ._section__slider-option-wrapper {
      background-size: 50% 80%;
    }
    ._section__testimonials {
      padding: 0;
    }
  }
}
</style>