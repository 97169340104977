<template>
  <div class="_slide-show__wrapper">
    <section ref="slideShow" class="_section__slide-show">
      <div class="_slide" v-for="slide in heroSlides" :key="slide.id">
        <div class="_media">
          <picture class="_picture">
            <source media="(min-width: 1200px)" :srcset="slide.largePath" />
            <source media="(min-width: 600px)" :srcset="slide.mediumPath" />
            <img class="_image" :src="slide.smallPath" :alt="slide.title" />
          </picture>
        </div>
        <div class="_card__custom">
          <h1 class="_title">{{ slide.title }}</h1>
          <div class="_paragraphs">
            <p class="_text">{{ slide.text }}</p>
          </div>
        </div>
        <div class="_links">
          <router-link
            class="_link"
            :to="{ name: slide.linkPath.name, hash: slide.linkPath.hash }"
            >{{ slide.link }}</router-link
          >
        </div>
      </div>
      <div ref="slideDots" class="_slide-show__dots">
        <a
          class="_dot"
          v-for="i in 3"
          :key="i"
          @click.prevent="showSlideShowItem(i)"
          href=""
        ></a>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "SlideShow",
  data() {
    return {
      currentHeroSlide: 1,
      slideShowTimer: null,
      slideShowTimerInterval: 8000,
      heroSlides: [
        {
          id: 1,
          smallPath: require("@/assets/images/homepage_hero_slide_2-1200.jpg"),
          mediumPath: require("@/assets/images/homepage_hero_slide_2-1200.jpg"),
          largePath: require("@/assets/images/homepage_hero_slide_2-1200.jpg"),
          title: "Reunite with your friends and family",
          text:
            "It goes without saying that the lockdown made us miss our loved ones. It's time to spend some moments of fun, love and laughter with your extended family and friends. Be it a get together or a grand celebration, reconnect with your loved ones at Kamal farms.",
          link: "View 5 Bhk Luxury Villa",
          linkPath: { name: "LuxuryVilla", hash: "" },
        },
        {
          id: 2,
          smallPath: require("@/assets/images/homepage_hero_slide_1-Small.jpg"),
          mediumPath: require("@/assets/images/homepage_hero_slide_1-Small.jpg"),
          largePath: require("@/assets/images/homepage_hero_slide_1-1200.jpg"),
          title: "Self Drive Getaway to Nature's Abode",
          text:
            "We understand that the global pandemic has confined us to our homes. But a short trip to Karjat in the safety and comfort of your own vehicle sounds ideal. Kamal farms offer their homestead to individuals and families looking for a perfect self- drive getaway.",
          link: "View Location",
          linkPath: { name: "Homepage", hash: "#location-map" },
        },

        {
          id: 3,
          smallPath: require("@/assets/images/homepage_hero_slide_3-Small.jpg"),
          mediumPath: require("@/assets/images/homepage_hero_slide_3-Small.jpg"),
          largePath: require("@/assets/images/homepage_hero_slide_3-1200.jpg"),
          title: "Wedding in the New Normal",
          text:
            "Having an extravagant wedding is nearly impossible in the COVID-19 era. With accommodation for upto 50 guests, have your destination wedding at Kamal Farms. Celebrate the best day of your life under the canopy of trees and the melody of Pej river.",
          link: "Know More",
          linkPath: { name: "Events", hash: "" },
        },
      ],
    };
  },
  methods: {
    showSlideShowItem(id) {
      this.currentHeroSlide = id;
      const slides = this.$refs.slideShow.getElementsByClassName("_slide");
      const dots = this.$refs.slideDots.getElementsByClassName("_dot");
      for (let index = 0; index < this.heroSlides.length; index++) {
        const slide = slides[index];
        const dot = dots[index];
        if (index === id - 1) {
          gsap.to(slide, { autoAlpha: 1 });
          dot.classList.add("_dot--active");
        } else {
          gsap.to(slide, { autoAlpha: 0 });
          dot.classList.remove("_dot--active");
        }
      }
    },
    startSlideShow() {
      console.log("startSlideShow");
      this.showSlideShowItem(this.currentHeroSlide);
      this.slideShowTimer = setInterval(() => {
        this.currentHeroSlide++;
        if (this.currentHeroSlide > 3) {
          this.currentHeroSlide = 1;
        }
        this.showSlideShowItem(this.currentHeroSlide);
      }, this.slideShowTimerInterval);
    },
    stopSlideShow() {
      console.log("stopSlideShow");
      window.clearInterval(this.slideShowTimer);
    },
  },
  mounted() {
    this.startSlideShow();
    // this.showSlideShowItem(this.currentHeroSlide);
  },
  beforeDestroy() {
    this.stopSlideShow();
  },
};
</script>

<style scoped lang="scss">
._dot {
  width: 15px;
  height: 15px;
  margin-left: 15px;
  background-color: $creme;
  border-radius: 50%;
  display: inline-block;
  transition: background-color $transitionDuration;
  &:first-child {
    margin-left: 0;
  }
  &--active {
    @extend ._dot;
    background-color: $ochre;
  }
}
// * ToDo: Important - This should not be a Section
// Lets try to refactor this whole code to adjust with _section__custom--simple or something!?
._section__slide-show {
  @extend ._section__custom--simple;
  // padding: 0;
  ._slide-show__dots {
    padding-top: $paddingMinimum;
    grid-area: 1/1/2/2;
    place-self: end;
    z-index: 1;
  }
  ._slide {
    display: grid;
    grid-template-rows: min-content auto auto;
    grid-area: 1/1/2/2;
  }
  ._links {
    // margin: auto 0;
    align-self: end;
    padding-top: $paddingMinimum;
  }
}

@media screen and(min-width: 600px) {
  ._section__slide-show {
    // ._media {
    //   max-height: 500px;
    // }
  }
}
@media screen and(min-width: 1200px) {
  ._section__slide-show {
    grid-template-columns: 70% 1fr;
    column-gap: Calc($paddingMinimum * 2);
    ._card {
      // padding: 0;
      margin: auto 0;
      grid-area: 1/2/2/3;
    }
    ._slide-show__dots {
      padding: 0;
      grid-area: 1/2/2/3;
      justify-self: start;
    }
    ._slide {
      grid-area: 1/1/2/3;
      grid-template-columns: 70% 1fr;
      grid-template-rows: 3fr 1fr;
      column-gap: Calc($paddingMinimum * 2);
    }
    ._media {
      grid-area: 1/1/3/2;
    }
    ._links {
      margin-bottom: auto;
      grid-area: 2/2/3/3;
    }
  }
}
</style>