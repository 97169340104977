import Vue from 'vue';
import App from './App.vue';
import router from './router';



import { auth } from './firebase';

Vue.config.productionTip = false;

Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },

  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

// import VueGtag from "vue-gtag";
// Vue.use(VueGtag, {
//   config: { id: "UA-183814080-1" }
// }, router);

let app;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      render: (h) => h(App),
    }).$mount('#app');
  }
});

// Custom Packages
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})