import Vue from 'vue';
import VueRouter from 'vue-router';
import Homepage from '@/views/Homepage.vue';
//import Home from '@/views/Home.vue';
// import About from '@/views/About.vue';
// import Test from '@/views/Test.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: Homepage,
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */ '@/views/Test.vue'),
  },
  {
    path: '/property',
    name: 'Property',
    // route level code-splitting
    // this generates a separate chunk (Property.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "luxuryvilla" */ '@/views/Property.vue'),
  },
  {
    path: '/luxury-villa',
    name: 'LuxuryVilla',
    // route level code-splitting
    // this generates a separate chunk (LuxuryVilla.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "luxuryvilla" */ '@/views/LuxuryVilla.vue'),
  },
  {
    path: '/riverside-cottage',
    name: 'RiversideCottage',
    // route level code-splitting
    // this generates a separate chunk (RiversideCottage.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "riversidecottage" */ '@/views/RiversideCottage.vue'
      ),
  },
  {
    path: '/sustainability',
    name: 'Sustainability',
    // route level code-splitting
    // this generates a separate chunk (Sustainability.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "sustainability" */ '@/views/Sustainability.vue'
      ),
  },
  {
    path: '/events',
    name: 'Events',
    // route level code-splitting
    // this generates a separate chunk (Events.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "events" */ '@/views/Events.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/About.vue'),
    // component: About,
  },
  {
    // Temporary till we don't have a 404 page designed
    path: '*',
    name: 'Default',
    component: Homepage,
  },
  // {
  //   path: '*',
  //   name: '404',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/404.vue'),
  //   // component: 404,
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 150 } };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
