<template>
  <div id="app">
    <AppHeader></AppHeader>

    <main>
      <transition name="slide" appear><router-view /></transition>
    </main>

    <AppFooter></AppFooter>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
const meta = {
  head: "Kamal Farms Karjat",
  title: "Home",
  description:
    "Kamal Farms through its two properties 5 bedroom riverview villa and 3 bedroom riverside cottage offers a perfect destination to let go and allow nature to unravel its magic for a calming experience.",
  url: "https://kamalfarms.com",
};
export default {
  name: "App",
  data() {
    return {};
  },
  metaInfo: {
    title: meta.title,
    titleTemplate: "%s | Kamal Farms Karjat",
    meta: [
      { charset: "utf-8" },
      // { name: "robots", content: "index,follow" },
      {
        name: "description",
        content: meta.description,
      },
      {
        property: "og:title",
        content: meta.title,
        template: (chunk) => `${chunk} | ${meta.head}`,
        vmid: "og:title",
      },
      {
        property: "og:description",
        content: meta.description,
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:url",
        content: meta.url,
      },
      {
        property: "og:image",
        content: `${meta.url}/meta-image.jpg`,
      },
      {
        name: "twitter:title",
        content: meta.title,
        template: (chunk) => `${chunk} | ${meta.head}`,
        vmid: "twitter:title",
      },
      {
        name: "twitter:description",
        content: meta.description,
      },
      {
        name: "twitter:card",
        content: "website",
      },
      {
        name: "twitter:url",
        content: meta.url,
      },
      {
        name: "twitter:image",
        content: `${meta.url}/meta-image.jpg`,
      },
    ],
    htmlAttrs: {
      lang: "en",
    },
  },
  components: { AppHeader, AppFooter },
};
</script>

// Apply Global Styles
<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
  /* text-decoration: none; */
}

html {
  /* ** ToDo - font-size should balways be 100% */
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  position: relative;
}
.slide-enter-active,
.slide-leave-active {
  transition: 0.25s all ease-out;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>
