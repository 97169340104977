<template>
  <header>
    <nav class="header__navigation">
      <div class="header__bg"></div>
      <router-link class="logo__link" :to="{ name: 'Homepage' }">
        <img
          class="logo__image"
          src="@/assets/logo.svg"
          alt="Kamal Farms Logo" />
        <img class="logo__text" src="@/assets/logo_text.svg" alt="Kamal Farms"
      /></router-link>
      <div
        class="menu-bar__icon"
        @click="toggleMenu"
        v-click-outside="hideMenu"
      >
        <div class="menu-bar--1"></div>
        <div class="menu-bar--2"></div>
        <div class="menu-bar--3"></div>
      </div>
      <div class="nav-items">
        <router-link
          class="nav-item"
          v-for="item in navItems"
          :key="item.id"
          :to="{ name: item.link, hash: item.hash }"
        >
          <div class="nav-item__link">
            {{ item.label }}
          </div>
        </router-link>
      </div>
    </nav>
  </header>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      currentItem: 0,
      showMenu: false,
      toggleMenuTL: undefined,
      collapseMenuTL: undefined,
      navItems: [
        { id: 1, label: "5 Bedroom Villa", link: "LuxuryVilla" },
        { id: 2, label: "3 Bedroom Cottage", link: "RiversideCottage" },
        { id: 3, label: "Sustainability", link: "Sustainability" },
        { id: 4, label: "Events & More", link: "Events" },
        { id: 5, label: "About Us", link: "About" },
        { id: 6, label: "Contact Us", link: "About", hash: "#contact-form" },
      ],
    };
  },
  methods: {
    onScroll() {
      //console.log("onScroll", window.innerWidth, scrollY);
      if (!this.showMenu) {
        if (scrollY > 5) {
          this.collapseMenuTL.play();
        } else {
          this.collapseMenuTL.reverse();
        }
      }
    },
    onResize() {
      //console.log(window.innerWidth);
      if (window.innerWidth > 1200) {
        // this.setToggleMenuAction();
        // } else {
        this.showMenu = false;
        this.onScroll();
        this.toggleMenuTL.reverse();
        // this.toggleMenu();
      }
    },
    hideMenu() {
      this.showMenu = false;
      this.onScroll();
      this.toggleMenuTL.reverse();
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      console.log("toggleMenu", this.showMenu);
      if (this.showMenu) {
        this.collapseMenuTL.reverse();
        this.toggleMenuTL.play();
      } else {
        this.onScroll();
        // this.collapseMenuTL.play();
        this.toggleMenuTL.reverse();
      }
    },
    setToggleMenuAction() {
      this.toggleMenuTL = gsap.timeline({ paused: true });
      this.toggleMenuTL.to(".menu-bar--1", {
        top: "50%",
        rotation: "45",
        transformOrigin: "center",
      });
      this.toggleMenuTL.to(".menu-bar--2", { opacity: 0 }, "-=0.5");
      this.toggleMenuTL.to(
        ".menu-bar--3",
        { top: "50%", rotation: "-45", transformOrigin: "center" },
        "-=0.5"
      );
      this.toggleMenuTL.to(
        ".header__navigation",
        {
          height: "100vh",
          backgroundColor: "white",
          duration: 0.25,
        },
        "-=0.5"
      );
      this.toggleMenuTL.set(".nav-items", { clearProps: "all" });
      this.toggleMenuTL.set(".nav-items", { display: "flex" });
      this.toggleMenuTL.to(".nav-items", {
        opacity: 1,
        duration: 0.25,
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);

    //if (window.innerWidth < 1200) {
    this.setToggleMenuAction();
    //}
    ///////////////////////////
    this.collapseMenuTL = gsap.timeline({
      paused: true,
      // scrollTrigger: {
      //   trigger: ".header__navigation",
      //   start: "0 top",
      //   end: "bottom center",
      //   toggleActions: "play none reverse none",
      //   // scrub: 1,
      //   markers: true,
      //   // toggleClass: "header__navigation--scrolled",
      //},
    });

    this.collapseMenuTL.to(".header__navigation", {
      //gridTemplateRows: "100px",
      duration: 0.5,
    });
    this.collapseMenuTL.from(
      ".header__bg",
      {
        //height: "100px",
        opacity: 0,
      },
      "-=0.5"
    );
    this.collapseMenuTL.to(
      ".logo__text",
      {
        autoAlpha: 0,
        //scale: 0.8,
      },
      "-=0.5"
    );
  },
};
</script>

<style scoped lang="scss">
.header {
  &__navigation {
    @extend .container;
    width: 100%;
    height: $headerBuffer;
    display: grid;
    grid-template-rows: $headerBuffer auto;
    grid-template-columns: min-content min-content;
    justify-content: space-between;
    align-items: center;
    transform: translate(-50%);
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 10;
    // background-color: rgba(255, 255, 255, 0.8);
    overflow: hidden;
    .header__bg {
      grid-area: 1/1/3/3;
      align-self: start;
      width: 100%;
      height: 100px;
      background-color: rgba($color: $white, $alpha: 0.9);
    }
    .logo {
      &__link {
        grid-area: 1/1/2/2;
        // height: 60px;
        margin: 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        z-index: 100;
      }
      &__image {
        padding: 0 0 5px;
        width: 100%;
      }
      &__text {
        // margin: 0 auto;
        width: 140px;
      }
    }
    .menu-bar {
      width: 100%;
      height: 1px;
      position: absolute;
      background-color: $dark-green;
      &__icon {
        grid-area: 1/2/2/3;
        align-self: start;
        height: 20px;
        width: 30px;
        margin: 40px 30px;
        cursor: pointer;
        position: relative;
        z-index: 100;
      }
      &--1 {
        @extend .menu-bar;
        top: 0;
      }
      &--2 {
        @extend .menu-bar;
        top: 50%;
      }
      &--3 {
        @extend .menu-bar;
        top: 100%;
      }
    }
    .nav-items {
      //align-self: stretch;
      grid-area: 1/1/3/3;
      display: none;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      opacity: 0;
      .nav-item {
        padding: 30px 0;
        &__link {
          @extend ._link;
          border-bottom: none;
          font-size: 1.8rem; //clamp(1.4rem, calc(1.25rem + 0.3vw), 1.8rem);
        }
        &:nth-child(6) {
          justify-items: stretch;
          display: flex;
          place-items: center;
          padding: 15px 30px;
          background-color: $dark-green;
          text-decoration: none;
          .nav-item__link {
            color: $white;
          }
        }
      }
      .router-link-active {
        .nav-item__link {
          font-weight: bold;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .header {
    &__navigation {
      .nav-items {
        grid-area: 2/1/3/3;
        .nav-item {
          padding: 25px 0;
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .header {
    &__navigation {
      .menu-bar__icon {
        display: none;
      }
      .nav-items {
        align-self: flex-start;
        // padding-top: 7.5px;
        opacity: 1;
        grid-area: 1/1/2/3;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        // align-items: flex-start;
        gap: 20px;
        .nav-item {
          &__link {
            font-size: clamp(1.4rem, calc(1.25rem + 0.3vw), 1.5rem);
          }
          &:nth-child(6) {
            height: 100px;
            // display: none;
          }
        }
      }
    }
  }
}
</style>